
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 require('./lazyobs');
 require('./webfontloader');

// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app'
// });

// let deferredPrompt;
//   var $promptBtn = $("#webAppPrompt");
//   //prompt to install the web app
//   window.addEventListener('beforeinstallprompt', (e) => {
//   // Prevent Chrome 67 and earlier from automatically showing the prompt
//   e.preventDefault();
//   // Stash the event so it can be triggered later.
//   deferredPrompt = e;
//   console.log('test');
//   });

//   // Update UI notify the user they can add to home screen
//   $promptBtn[0].style.display = 'block';

//   $promptBtn[0].addEventListener('click', (e) => {
//   // hide our user interface that shows our A2HS button
//   $promptBtn[0].style.display = 'none';
//   // Show the prompt
//   deferredPrompt.prompt();
//   // Wait for the user to respond to the prompt
//   deferredPrompt.userChoice
//     .then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the A2HS prompt');
//       } else {
//         console.log('User dismissed the A2HS prompt');
//       }
//       deferredPrompt = null;
//     });
// });

$(function(){
	$(".products-carousel").owlCarousel({
		items:1,
		pagination:false,
		navigation:true,
		slideSpeed:700,
		responsiveRefreshRate:0,
	});
	$('.products-gallery').each(function(){
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
			removalDelay: 300,
			tLoading: 'Loading image #%curr%...',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload:[0,1]
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				titleSrc: function(item) {
					return item.el.attr('title') + '<small></small>';
				}
			}

		});
	});
	$('.colors-gallery').each(function(){
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
			removalDelay: 300,
			tLoading: 'Loading image #%curr%...',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload:[0,1]
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				titleSrc: function(item) {
					return item.el.attr('title') + '<small></small>';
				}
			}

		});
	});
	$(".related-products").owlCarousel({
		itemsMobile:[479,1],
		itemsTablet:[768,2],
		itemsDesktopSmall:[979,2],
		itemsDesktop:[1250,3],
		items:3,
		autoplay: true,
		pagination:false,
		navigation:true,
		slideSpeed:700,
		responsiveRefreshRate:0,
	});
	$( ".related-products .owl-prev").html('<button title="Prev (Left arrow key)" type="button" class="mfp-arrow mfp-arrow-left mfp-prevent-close"></button>');
	$( ".related-products .owl-next").html('<button title="Next (Right arrow key)" type="button" class="mfp-arrow mfp-arrow-right mfp-prevent-close"></button>');
});