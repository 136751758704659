 var WebFont = require('webfontloader');
  //webfont library
  WebFont.load({
    google: {
      families: ['Open+Sans:400,400i,600,700&amp;subset=latin-ext']
    },
    timeout: 2000,
    active: function() {
      sessionStorage.fonts = true;
    }
  });